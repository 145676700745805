

import { Injectable, OnInit, signal, WritableSignal } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { User_Role } from '../auth.enum';
import { KeycloakTokenParsed, KeycloakProfile } from 'keycloak-js';
import { BehaviorSubject, Observable } from 'rxjs';



export interface ICurrentUser {
  
    userId: string | undefined,
    userName: string | undefined,
    email: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined
    //profileCreationStaus: string | undefined
  
}


export interface IAuthService {
  getLoggedInUser(): KeycloakTokenParsed | undefined ,
  getUserName() :string ,
  getUserEmail(): string | undefined ,
  getLoggedInUserDetails(): Promise<ICurrentUser | undefined>,
  getLoggedInUserToken(): string | undefined,
//  isUserLoggedIn(): Promise<boolean> ,
  isUserLoggedIn(): boolean ,
  loadUserProfile(): Promise<KeycloakProfile>,
  login(): void ,
  logout(): void ,
  register(): void ;
  hasRole(role: string): boolean,
  getUserRole() : User_Role 
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthService, OnInit {


  private authState: WritableSignal<'loading' | 'loggedIn' | 'loggedOut'> = signal('loading');

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  

  constructor(private keycloakService: KeycloakService) {

    this.currentUserSubject = new BehaviorSubject<any>(null);

    this.currentUser = this.currentUserSubject.asObservable();

    //this.isUserLoggedIn();

  }

  ngOnInit(): void {    
      this.keycloakService.keycloakEvents$.subscribe({
        next: (event) => {
          if (event.type == KeycloakEventType.OnTokenExpired) {
            this.keycloakService.updateToken(20);
          }
        }
      });
  }
  /*ngOnInit(): void {
  this.keycloakService.keycloakEvents$.subscribe({
    next: this.handleKeycloakEvent.bind(this)
  });
}

private handleKeycloakEvent(event: KeycloakEvent): void {
  if (event.type === KeycloakEventType.OnTokenExpired) {
    this.keycloakService.updateToken(20);
  }
}
*/

  getLoggedInUser(): KeycloakTokenParsed | undefined {
    try {
      const userDetails: KeycloakTokenParsed | undefined =
        this.keycloakService.getKeycloakInstance().idTokenParsed;   
      localStorage.setItem("currentUser", JSON.stringify(userDetails));
      this.currentUserSubject.next(userDetails);

      return userDetails;
    }
    catch (e) {    
      console.error(e);
      return undefined;
    }
  }
  getUserEmail(): string | undefined {
    let email2;
    this.keycloakService.loadUserProfile().then(profile => {
      console.log(profile.username);
      console.log(profile.email)
      email2 = profile.email;
    });

    return email2;
  }
  getLoggedInUserDetails(): Promise<ICurrentUser | undefined> {
   
   return this.keycloakService.loadUserProfile().then(profile => {
      console.log(profile.username);
      console.log(profile.email);
      console.log(profile.id);
      console.log(profile.lastName);
     let  outer_profile : ICurrentUser  =
        {
          userId: profile.id,
          userName: profile.username,
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName
         // profileCreationStatus: undefined
        };
        console.log("...........................>>>>>>>>>>>>>>>>>>>>>>>>  "+outer_profile);
        return outer_profile;
    });
    
   
  }
  getLoggedInUserToken(): string | undefined {
    try {
      const token: string | undefined =
        this.keycloakService.getKeycloakInstance().token      
      // localStorage.setItem("currentUser",JSON.stringify(token));
      this.currentUserSubject.next(token);
      return token;
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  }
  
  isUserLoggedIn(): boolean {
    // Set loading state while checking the login status
    this.authState.set('loading');

    const loggedIn = this.keycloakService.isLoggedIn();
    
    // Simulate async login check
    setTimeout(() => {
      if (loggedIn) {
        this.authState.set('loggedIn');
        console.log("loggedIn" + loggedIn);
      } else {
        this.authState.set('loggedOut');
        console.log("loggedIn" + loggedIn);
      }
    }, 0); // Adjust delay as needed

    console.log("loggedIn" + loggedIn);
    return loggedIn;
  }

  
  loadUserProfile(): Promise<KeycloakProfile> {
    return this.keycloakService.loadUserProfile();   
  }

  /**
   * Checks if the user is logged in.
   * @returns A promise that resolves to true if the user is logged in, false otherwise.
   */
  isLoggedIn(): boolean {
    return this.keycloakService.isLoggedIn();
  }

  /**
   * Initiates the login process.
   */
  login(): void {
    this.keycloakService.login();
  }

  /**
   * Logs the user out and redirects them to the given URL.
   * @param redirectUri The URL to which the user should be redirected after logout.
   */
  logout(redirectUri?: string): void {
    this.keycloakService.logout(redirectUri || window.location.origin);
  }

  /**
   * Retrieves the username of the logged-in user.
   * @returns The username of the logged-in user.
   */
  getUsername(): string {
    return this.keycloakService.getUsername();
  }

  /**
   * Retrieves the authentication token.
   * @returns A promise that resolves to the authentication token.
   */
  getToken(): Promise<string> {
    return this.keycloakService.getToken();
  }
  
  public hasRole(role: string): boolean {
    return this.keycloakService.isUserInRole(role);
  }

  getUserRole() : User_Role {

    if(this.hasRole(User_Role.Candidate)){
      //console.warn("applicant!!");
      return User_Role.Candidate;

    }else if(this.hasRole(User_Role.Employer)){
     // console.warn("administrator!!");
      return User_Role.Employer;
    }else{
      //console.warn("Unknown Role");
      //return User_Role.None;
      //return User_Role.Unkwnown;
      return User_Role.Default
    }
  }

  getUserRoles() {
    return this.keycloakService.getUserRoles();
  }

  
  public getUserName() {
    return this.keycloakService.getUsername();
  }

  public register(): void {
    this.keycloakService.register();
  }

  getAuthState() {
    return this.authState;
  }

}
